.category-box {
  overflow: unset;
  padding: 20px 15px;
}

.category-box__list {
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(271px, 1fr));
  //gap: 27px;
  //justify-content: center;
  //padding: 7px 0;
  //text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.category-box__list-item {
  width: 271px;
}

.category-box__list-link {
  overflow: hidden;
  display: flex;
align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  @include media-max(768px) {
    max-width: 270px;
    margin: 0 auto;
  }

  @include stateDefault(#000000) {
    opacity: 0.8;
  }
}

.category-box__list-img {
  position: relative;
  display: block;
  width: 270px;
  height: 270px;
  margin: 0 auto;

  &::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: -1px;
    left: 0;
    height: 124px;
    background: linear-gradient(180.00deg, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.category-box__title {
  display: inline-block;
  padding: 17px 10px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
