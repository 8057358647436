*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

header .top {
  height: auto;
  min-height: 41px;
}

header .phones a:not(:last-child):after {
  margin: 0 21px;
}

header .menu > li a, header .menu > li label {
  font-weight: 300;
}

.row {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.top .container {
  padding: 0;
}

header .menu-col {
  padding: 0;
}

header .menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 13px;
  font-family: "Roboto Condensed", sans-serif;
}

.footer-home {
  margin: 0;
}

.container-footer .row {
  justify-content: center;
  padding: 27px 0 47px;
}

.container-footer .header {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  line-height: 23px;
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.btn-state {
  transition: color $trans-default, background-color $trans-default;
  @include stateDefault(#ffffff) {
    background-color: #000000;
  }
}
