.slider.full-width {
  max-width: 1920px;
  margin: 0 auto;
}

.slider__swiper {
  padding: 10px 0 62px;
}

.slider__swiper-slide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 78px 0 50px;

  @include media-max(768px) {
    min-height: 300px;
  }

  @include media-min-max(768px, 1200px) {
    min-height: 500px;
  }

  @include media-min(1200px) {
    min-height: 774px;
  }
}

.slider__img {
  position: absolute;
  inset: 0;

  picture {
    display: contents;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slider__content {
  position: relative;
  z-index: 1;

}

.slider__main-title {
  position: relative;
  margin: 0 0 52px;
  padding-bottom: 40px;
  font-family: "Mont", sans-serif;
  //font-size: 87px;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135.60deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.47), rgb(0, 0, 0));
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 214px;
    height: 1px;
    background-color: #000000;
    box-shadow: 0px 4.3px 40.68px 0px rgba(0, 0, 0, 0.25);
  }

  @include media-max(768px) {
    font-size: 30px;
  }

  @include media-min-max(768px, 1200px) {
    font-size: 50px;
  }

  @include media-min(1200px) {
    font-size: 87px;
  }
}

.slider__btn-link {
  display: block;
  margin: 0 auto;
  padding: 18px 40px 20px;
  font-family: "Mont", sans-serif;
  //font-size: 47px;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(180deg, #000000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), transparent);
  -webkit-background-clip: text;
  background-clip: text;
  border: 1px solid rgba(#D9D9D9, 0.2);
  border-image-source: linear-gradient(135.60deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.47), rgb(0, 0, 0));
  border-image-slice: 1;
  text-fill-color: transparent;

  @include stateDefault(#000000) {
    -webkit-text-fill-color: unset;
    background: none !important;
    border-color: #000000;
    border-image: none;
  }

  @include media-max(768px) {
    font-size: 20px;
  }

  @include media-min-max(768px, 1200px) {
    font-size: 25px;
  }

  @include media-min(1200px) {
    font-size: 47px;
  }
}

.slider .swiper-slide a {
  width: fit-content;
}

.slider.full-width .swiper-pagination {
  left: 0;
}

.slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.2);

  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px inset #ffffff;
  transition: color 0.2s ease, width 0.2s ease, background-color 0.2s ease;

  @include stateDefault(#000000) {

  }
}

.slider.full-width .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background: #e7c78c linear-gradient(180.00deg, rgb(231, 199, 140), rgb(188, 156, 97));
  box-shadow: none;
}
