/* здесь подключите шрифты*/
// @mixin font($font_name, $file_name, $weight, $style)
// @mixin fontFolder($font_name, $file__direct, $file_name, $weight, $style)

// Так как в сборке в css настроены пути относительно файлов scss,
// то в пути нужно прописать "../../img" или "../../fonts"

@font-face {
  @include fontFolder("Roboto", roboto, roboto-100, 100, normal);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-300, 300, normal);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-400, 400, normal);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-500, 500, normal);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-500italic, 500, italic);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-700, 700, normal);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-700italic, 700, italic);
}

@font-face {
  @include fontFolder("Roboto", roboto, roboto-900, 900, normal);
}

@font-face {
  @include fontFolder("Roboto Condensed", roboto-condensed, roboto-condensed-200italic, 200, italic);
}

@font-face {
  @include fontFolder("Mont", mont, mont-300, 300, normal);
}
