.new-goods {
  padding: 35px 0 0;

  .catalog-list {
    margin: 0;
  }
}

.new-goods__container {
  max-width: 1920px;
}

.new-goods__wrapper {
  @include media-max(1024px) {
    padding-bottom: 30px;
  }

  @include media-min(1024px) {
    padding-bottom: 86px;
  }
}

.new-goods__slide {
  width: 300px;
}
