@charset "UTF-8";
/* здесь подключите шрифты*/
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-100.woff2") format("woff2"), url("../../fonts/roboto/roboto-100.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-300.woff2") format("woff2"), url("../../fonts/roboto/roboto-300.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-400.woff2") format("woff2"), url("../../fonts/roboto/roboto-400.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-500.woff2") format("woff2"), url("../../fonts/roboto/roboto-500.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-500italic.woff2") format("woff2"), url("../../fonts/roboto/roboto-500italic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-700.woff2") format("woff2"), url("../../fonts/roboto/roboto-700.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-700italic.woff2") format("woff2"), url("../../fonts/roboto/roboto-700italic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/roboto/roboto-900.woff2") format("woff2"), url("../../fonts/roboto/roboto-900.woff") format("woff");
}
@font-face {
  font-family: "Roboto Condensed";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/roboto-condensed/roboto-condensed-200italic.woff2") format("woff2"), url("../../fonts/roboto-condensed/roboto-condensed-200italic.woff") format("woff");
}
@font-face {
  font-family: "Mont";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/mont/mont-300.woff2") format("woff2"), url("../../fonts/mont/mont-300.woff") format("woff");
}
.page-title {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 54px 0;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  line-height: 28px;
  font-weight: 500;
  font-style: italic;
  color: #000000;
  text-align: center;
  letter-spacing: -3px;
}
@media (max-width: 1199px) {
  .page-title {
    padding: 30px 0;
  }
}
.page-title::after {
  position: absolute;
  content: attr(data-title);
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  opacity: 0.1;
  background: linear-gradient(180deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
}
@media (max-width: 767px) {
  .page-title::after {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .page-title::after {
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 110px;
    line-height: 100px;
  }
}
@media (min-width: 1200px) {
  .page-title::after {
    font-size: 216px;
    line-height: 165px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

header .top {
  height: auto;
  min-height: 41px;
}

header .phones a:not(:last-child):after {
  margin: 0 21px;
}

header .menu > li a, header .menu > li label {
  font-weight: 300;
}

.row {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
}

.top .container {
  padding: 0;
}

header .menu-col {
  padding: 0;
}

header .menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 13px;
  font-family: "Roboto Condensed", sans-serif;
}

.footer-home {
  margin: 0;
}

.container-footer .row {
  justify-content: center;
  padding: 27px 0 47px;
}

.container-footer .header {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  line-height: 23px;
  font-weight: 200;
  font-style: italic;
  text-align: center;
}

.btn-state {
  transition: color 0.3s ease, background-color 0.3s ease;
}
@media (hover: hover) {
  .btn-state:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
.btn-state:focus-visible {
  color: #ffffff;
  outline: none;
  background-color: #000000;
}
@supports not selector(:focus-visible) {
  .btn-state:focus {
    color: #ffffff;
    outline: none;
    background-color: #000000;
  }
}
.btn-state:active {
  opacity: 0.5;
}

.slider.full-width {
  max-width: 1920px;
  margin: 0 auto;
}

.slider__swiper {
  padding: 10px 0 62px;
}

.slider__swiper-slide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 78px 0 50px;
}
@media (max-width: 767px) {
  .slider__swiper-slide {
    min-height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .slider__swiper-slide {
    min-height: 500px;
  }
}
@media (min-width: 1200px) {
  .slider__swiper-slide {
    min-height: 774px;
  }
}

.slider__img {
  position: absolute;
  inset: 0;
}
.slider__img picture {
  display: contents;
}
.slider__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__content {
  position: relative;
  z-index: 1;
}

.slider__main-title {
  position: relative;
  margin: 0 0 52px;
  padding-bottom: 40px;
  font-family: "Mont", sans-serif;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135.6deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.47), rgb(0, 0, 0));
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
}
.slider__main-title::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 214px;
  height: 1px;
  background-color: #000000;
  box-shadow: 0px 4.3px 40.68px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 767px) {
  .slider__main-title {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .slider__main-title {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .slider__main-title {
    font-size: 87px;
  }
}

.slider__btn-link {
  display: block;
  margin: 0 auto;
  padding: 18px 40px 20px;
  font-family: "Mont", sans-serif;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(180deg, #000000, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), transparent);
  -webkit-background-clip: text;
  background-clip: text;
  border: 1px solid rgba(217, 217, 217, 0.2);
  border-image-source: linear-gradient(135.6deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.38), rgb(0, 0, 0), rgba(0, 0, 0, 0.47), rgb(0, 0, 0));
  border-image-slice: 1;
  text-fill-color: transparent;
}
@media (hover: hover) {
  .slider__btn-link:hover {
    color: #000000;
    -webkit-text-fill-color: unset;
    background: none !important;
    border-color: #000000;
    border-image: none;
  }
}
.slider__btn-link:focus-visible {
  color: #000000;
  outline: none;
  -webkit-text-fill-color: unset;
  background: none !important;
  border-color: #000000;
  border-image: none;
}
@supports not selector(:focus-visible) {
  .slider__btn-link:focus {
    color: #000000;
    outline: none;
    -webkit-text-fill-color: unset;
    background: none !important;
    border-color: #000000;
    border-image: none;
  }
}
.slider__btn-link:active {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .slider__btn-link {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .slider__btn-link {
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
  .slider__btn-link {
    font-size: 47px;
  }
}

.slider .swiper-slide a {
  width: fit-content;
}

.slider.full-width .swiper-pagination {
  left: 0;
}

.slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px inset #ffffff;
  transition: color 0.2s ease, width 0.2s ease, background-color 0.2s ease;
}
@media (hover: hover) {
  .slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):hover {
    color: #000000;
  }
}
.slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):focus-visible {
  color: #000000;
  outline: none;
}
@supports not selector(:focus-visible) {
  .slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):focus {
    color: #000000;
    outline: none;
  }
}
.slider.full-width .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active):active {
  opacity: 0.5;
}

.slider.full-width .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
  background: #e7c78c linear-gradient(180deg, rgb(231, 199, 140), rgb(188, 156, 97));
  box-shadow: none;
}

.brands-box__swiper {
  overflow: unset;
}

.brands-box__wrapper {
  padding: 20px 0;
  transition-timing-function: linear;
}

.brands-box__swiper--first .brands-box__wrapper {
  padding-top: 55px;
  padding-bottom: 4px;
}

.brands-box__swiper--second .brands-box__wrapper {
  padding-bottom: 42px;
}

.brands-box__slide {
  width: fit-content;
}

.brands-box__link {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 204px;
  height: 89px;
  padding: 15px 40px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
}
.brands-box__link img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (hover: hover) {
  .brands-box__link:hover {
    color: #000000;
    opacity: 0.8;
  }
}
.brands-box__link:focus-visible {
  color: #000000;
  outline: none;
  opacity: 0.8;
}
@supports not selector(:focus-visible) {
  .brands-box__link:focus {
    color: #000000;
    outline: none;
    opacity: 0.8;
  }
}
.brands-box__link:active {
  opacity: 0.5;
}

@media (max-width: 767px) {
  .products {
    padding: 20px 0 17px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .products {
    padding: 50px 0 17px;
  }
}
@media (min-width: 1024px) {
  .products {
    padding: 87px 0 17px;
  }
}

.products__container {
  max-width: 1500px;
  padding-bottom: 17px;
}
@media (max-width: 767px) {
  .products__container {
    padding-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .products__container {
    padding-top: 50px;
    padding-bottom: 17px;
  }
}
@media (min-width: 1024px) {
  .products__container {
    padding-top: 87px;
  }
}

.products__brands-container {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.products__brands-all {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.category-box {
  overflow: unset;
  padding: 20px 15px;
}

.category-box__list {
  width: fit-content;
  margin: 0 auto;
}

.category-box__list-item {
  width: 271px;
}

.category-box__list-link {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .category-box__list-link {
    max-width: 270px;
    margin: 0 auto;
  }
}
@media (hover: hover) {
  .category-box__list-link:hover {
    color: #000000;
    opacity: 0.8;
  }
}
.category-box__list-link:focus-visible {
  color: #000000;
  outline: none;
  opacity: 0.8;
}
@supports not selector(:focus-visible) {
  .category-box__list-link:focus {
    color: #000000;
    outline: none;
    opacity: 0.8;
  }
}
.category-box__list-link:active {
  opacity: 0.5;
}

.category-box__list-img {
  position: relative;
  display: block;
  width: 270px;
  height: 270px;
  margin: 0 auto;
}
.category-box__list-img::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: -1px;
  left: 0;
  height: 124px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%);
}
.category-box__list-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.category-box__title {
  display: inline-block;
  padding: 17px 10px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.new-goods {
  padding: 35px 0 0;
}
.new-goods .catalog-list {
  margin: 0;
}

.new-goods__container {
  max-width: 1920px;
}

@media (max-width: 1023px) {
  .new-goods__wrapper {
    padding-bottom: 30px;
  }
}
@media (min-width: 1024px) {
  .new-goods__wrapper {
    padding-bottom: 86px;
  }
}

.new-goods__slide {
  width: 300px;
}

.blog-last {
  padding: 30px 0;
}

.blog-last__main-title {
  margin-bottom: 11px;
}

.blog-last__container {
  max-width: 1920px;
}
@media (min-width: 1500px) {
  .blog-last__container {
    padding: 0;
  }
}

.blog-last__wrapper {
  width: fit-content;
  margin: 0 auto;
}

.blog-last__slide {
  height: auto;
}
@media (max-width: 767px) {
  .blog-last__slide {
    width: 280px;
  }
}
@media (min-width: 768px) {
  .blog-last__slide {
    width: 370px;
  }
}

.blog-last__btn-all {
  display: block;
  width: fit-content;
  margin: 60px auto 0;
}

.blog-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #000000;
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 30px;
  transition: color 0.3s ease, background-color 0.3s ease;
}
@media (hover: hover) {
  .blog-card:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
.blog-card:focus-visible {
  color: #ffffff;
  outline: none;
  background-color: #000000;
}
@supports not selector(:focus-visible) {
  .blog-card:focus {
    color: #ffffff;
    outline: none;
    background-color: #000000;
  }
}
.blog-card:active {
  opacity: 0.5;
}

.blog-card__img {
  overflow: hidden;
  display: block;
  width: 100%;
  background-color: #444444;
  border-radius: 30px 30px 0 30px;
  box-shadow: 0px 4px 150px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 767px) {
  .blog-card__img {
    height: 280px;
  }
}
@media (min-width: 768px) {
  .blog-card__img {
    height: 370px;
  }
}
.blog-card__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card__content {
  display: block;
  padding: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  line-height: 23px;
  color: currentColor;
  letter-spacing: 1px;
}
.blog-card__content p {
  margin: 0;
}

.blog-card__title {
  font-weight: 500;
}

.burger-menu {
  position: relative;
  z-index: -100;
}

#burger-toggle + .burger-menu .close-block {
  visibility: hidden;
  opacity: 0;
}

#burger-toggle:checked + .burger-menu .close-block {
  visibility: visible;
  opacity: 1;
}