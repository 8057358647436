.burger-menu {
  position: relative;
  z-index: -100;
}

#burger-toggle+.burger-menu .close-block {
  visibility: hidden;
  opacity: 0;
}

#burger-toggle:checked+.burger-menu .close-block {
  visibility: visible;
  opacity: 1;
}
