// Desktop first
@mixin media-min-max($min-media, $max-media) {
  @media (min-width: $min-media) and (max-width: ($max-media - 1px)) {
    @content;
  }
}

@mixin media-max($max-media) {
  @media (max-width: ($max-media - 1px)) {
    @content;
  }
}

// Mobile first
@mixin media-min($min-media) {
  @media (min-width: $min-media) {
    @content;
  }
}

@mixin media($retina) {
  @media (min-resolution: $retina) {
    @content;
  }
}
