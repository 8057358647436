.products {
  @include media-max(768px) {
    padding: 20px 0 17px;
  }

  @include media-min-max(768px, 1024px) {
    padding: 50px 0 17px;
  }

  @include media-min(1024px) {
    padding: 87px 0 17px;
  }
}

.products__container {
  max-width: 1500px;
  padding-bottom: 17px;

  @include media-max(768px) {
    padding-top: 20px;
  }

  @include media-min-max(768px, 1024px) {
    padding-top: 50px;
    padding-bottom: 17px;
  }

  @include media-min(1024px) {
    padding-top: 87px;
  }
}

.products__brands-container {
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.products__brands-all {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
