.blog-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #000000;
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 30px;
  transition: color $trans-default, background-color $trans-default;

  @include stateDefault(#ffffff) {
    background-color: #000000;
  }
}

.blog-card__img {
  overflow: hidden;
  display: block;
  width: 100%;
  background-color: #444444;
  border-radius: 30px 30px 0 30px;
  box-shadow: 0px 4px 150px 0px rgba(0, 0, 0, 0.15);

  @include media-max(768px) {
    height: 280px;
  }

  @include media-min(768px) {
    height: 370px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-card__content {
  display: block;
  padding: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  line-height: 23px;
  color: currentColor;
  letter-spacing: 1px;

  p {
    margin: 0;
  }
}

.blog-card__title {
  font-weight: 500;
}
