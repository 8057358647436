.page-title {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 54px 0;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  line-height: 28px;
  font-weight: 500;
  font-style: italic;
  color: #000000;
  text-align: center;
  letter-spacing: -3px;

  @include media-max(1200px) {
    padding: 30px 0;
  }

  &::after {
    position: absolute;
    content: attr(data-title);
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px;
    //font-size: 216px;
    //line-height: 165px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    opacity: 0.1;
    background: linear-gradient(180.00deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;

    @include media-max(768px) {
      display: none;
    }

    @include media-min-max(768px, 1200px) {
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 110px;
      line-height: 100px;
    }

    @include media-min(1200px) {
      font-size: 216px;
      line-height: 165px;
    }
  }
}
