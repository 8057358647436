// @include font("Name Fonts", name-file, 700, normal);
@mixin font($font_name, $file_name, $weight, $style) {
    font-family: $font_name;
    font-weight: #{$weight};
    font-style: #{$style};
    font-display: swap;
    src: url("../../fonts/#{$file_name}.woff2") format("woff2"),
    url("../../fonts/#{$file_name}.woff") format("woff");
}

// @include fontFolder("Name Fonts", name-folder, name-file, 700, normal);
@mixin fontFolder($font_name, $file__direct, $file_name, $weight, $style) {
    font-family: $font_name;
    font-weight: #{$weight};
    font-style: #{$style};
    font-display: swap;
    src: url("../../fonts/#{$file__direct}/#{$file_name}.woff2") format("woff2"),
    url("../../fonts/#{$file__direct}/#{$file_name}.woff") format("woff");
}
