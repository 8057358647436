.blog-last {
  padding: 30px 0;
}

.blog-last__main-title {
  margin-bottom: 11px;
}

.blog-last__container {
  max-width: 1920px;

  @include media-min(1500px) {
    padding: 0;
  }
}

.blog-last__wrapper {
  width: fit-content;
  margin: 0 auto;
}
.blog-last__slide {
  height: auto;
  @include media-max(768px) {
    width: 280px;
  }

  @include media-min(768px) {
    width: 370px;
  }
}

.blog-last__btn-all {
  display: block;
  width: fit-content;
  margin: 60px auto 0;
}
