@mixin stateDefault($color) {
  @media (hover: hover) {
    &:hover {
      color: $color;

      @content;
    }
  }

  &:focus-visible {
    color: $color;

    outline: none;

    @content;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      color: $color;

      outline: none;

      @content;
    }
  }

  &:active {
    opacity: $active-default;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin focus {
  &:focus-visible {
    outline: none;
    @content;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      outline: none;
      @content;
    }
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  &:focus-visible {
    outline: none;
    @content;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      outline: none;
      @content;
    }
  }
}

@mixin active {
  &:active {
    @content;
  }
}

@mixin disabled {
  &:disabled,
  &--disabled {
    @content;
  }
}

@mixin interactive($item) {
  @if $item == hover {
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  }

  @if $item == focus {
    &:focus-visible {
      outline: none;
      @content;
    }

    @supports not selector(:focus-visible) {
      &:focus {
        outline: none;
        @content;
      }
    }
  }

  @if $item == hover-focus {
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }

    &:focus-visible {
      outline: none;
      @content;
    }

    @supports not selector(:focus-visible) {
      &:focus {
        outline: none;
        @content;
      }
    }
  }
}

// @mixin hover {
//   @media (hover: hover) {
//     &:hover:not(.focus-visible) {
//       @content;
//     }
//   }
// }

// @mixin focus {
//   &.focus-visible:focus {
//     @content;
//   }
// }

// @mixin active {
//   &.focus-visible:active {
//     @content;
//   }
// }

// @mixin hover-focus {
//   @media (hover: hover) {
//     &:hover:not(.focus-visible) {
//       @content;
//     }
//   }

//   &.focus-visible:focus {
//     @content;
//   }
// }
