.brands-box__swiper {
  overflow: unset;
}

.brands-box__wrapper {
  padding: 20px 0;
  transition-timing-function: linear;
}

.brands-box__swiper--first .brands-box__wrapper {
  padding-top: 55px;
  padding-bottom: 4px;
}

.brands-box__swiper--second .brands-box__wrapper {
  padding-bottom: 42px;
}

.brands-box__slide {
  width: fit-content;
}

.brands-box__link {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 204px;
  height: 89px;
  padding: 15px 40px;

  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include stateDefault(#000000) {
    opacity: 0.8;
  }
}
